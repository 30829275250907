import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { handleSignIn } from '../../services/auth'

const FORM_STYLES = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}

const SignInForm = ({ onSignIn }) => {
  const [password, setPassword] = useState('')
  const [hasError, setHasError] = useState(false)
  const { microcmsSettings } = useStaticQuery(pageQuery)

  let handleInputChange = event => {
    const { value } = event.target
    setPassword(value)
  }

  let handleSubmit = e => {
    e.preventDefault()
    if (password === microcmsSettings.password) {
      setHasError(false)
      handleSignIn()
      onSignIn()
    } else setHasError(true)
  }

  return (
    <>
      <p className="small">
        視聴にはパスワードが必要です。
        <br />
        <a href={'mailto:' + microcmsSettings.mail}>メール</a>
        にてお問い合わせください。
      </p>
      <p className="small">
        Password is required to watch videos on this page.
        <br />
        Please contact me via{' '}
        <a href={'mailto:' + microcmsSettings.mail}>email</a>.
      </p>

      <label>{hasError ? '正しい' : ''}パスワードを入力してください。</label>

      <form method="post" onSubmit={handleSubmit} style={FORM_STYLES}>
        <input
          type="text"
          name="password"
          placehoder="閲覧パスワードを入力してください"
          value={password}
          onChange={handleInputChange}
          className="field left"
        />

        <button type="submit">送信</button>
      </form>
    </>
  )
}

export const pageQuery = graphql`
  query {
    microcmsSettings {
      mail
      password
    }
  }
`
export default SignInForm
