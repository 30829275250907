import React from 'react'
import { useStaticQuery } from 'gatsby'

const SocialIcons = () => {
  const { allMicrocmsSettings } = useStaticQuery(pageQuery)
  const { mail, instagram, facebook } = allMicrocmsSettings.nodes[0]

  return (
    <>
      <ul className="icons">
        <li>
          <a href={'mailto:' + mail} className="icon fa-envelope">
            <span className="label">e-mail</span>
          </a>
        </li>
        <li>
          <a
            href={'https://facebook.com/' + facebook}
            target="_blank"
            rel="noopener"
            className="icon fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href={'https://instagram.com/' + instagram}
            target="_blank"
            rel="noopener"
            className="icon fa-instagram"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
    </>
  )
}

export const pageQuery = graphql`
  query {
    allMicrocmsSettings {
      nodes {
        facebook
        instagram
        mail
      }
    }
  }
`

export default SocialIcons
