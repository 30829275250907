import React, { useState } from 'react'
import Modal from '../Widgets/Modal'

const WorksElement = ({
  node,
  onOpenModal,
  onCloseModal,
  onSignIn,
  isSignedIn,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const imgSrc = node.pictures.sizes[3].link
  let download = node.download
    .filter(file => file.height === 1080 && file.quality === 'hd')
    .map(file => file.link)

  let handleOpenModal = () => {
    setIsOpen(true)
    onOpenModal()
  }

  let handleCloseModal = () => {
    setIsOpen(false)
    onCloseModal()
  }

  const wWidth = typeof window !== `undefined` ? window.innerWidth : 1200
  let vWidth = 640
  if (wWidth < 860) {
    vWidth = 400
  }
  if (wWidth < 500) {
    vWidth = 320
  }
  const vHeight = Math.floor((vWidth / 16) * 9)

  return (
    <div className="galleryItem">
      <img
        src={imgSrc}
        onClick={handleOpenModal}
        onKeyDown={handleOpenModal}
        className="image fit thumb"
        alt={'video thumbnail of ' + node.name}
      />
      <h5>{node.name}</h5>

      <Modal
        open={isOpen}
        onCloseModal={handleCloseModal}
        isPublic={false}
        onSignIn={onSignIn}
        isSignedIn={isSignedIn}
      >
        <h3>{node.name}</h3>
        <video
          id={node.id}
          src={node.srcset[0].link}
          width={vWidth}
          height={vHeight}
          controls
          className="fit thumb"
        />
        <div>
          <a className="button icon fa-download right" href={download}>
            DOWNLOAD
          </a>
        </div>
      </Modal>
    </div>
  )
}

export default WorksElement
