import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const Biography = ({ data }) => {
  const { microcmsSettings, microcmsPages } = useStaticQuery(pageQuery)
  const { title, body, hero } = microcmsPages
  return (
    <>
      <h2 className="major">{title}</h2>
      <span className="image main">
        <img
          src={hero.url}
          alt="Hajime Murakami airplane in the background"
          width="50%"
        />
      </span>
      <div dangerouslySetInnerHTML={{ __html: body }} />
      <p>
        <a
          href={microcmsSettings.profile_pdf}
          className="button icon fa-download"
          target="_blank"
          rel="noopener"
        >
          DOWNLOAD BIOGRAPHY / プロフィールのダウンロード
        </a>
      </p>
    </>
  )
}

export const pageQuery = graphql`
  query {
    microcmsSettings {
      password
      profile_pdf
    }
    microcmsPages(pagesId: { eq: "biography" }) {
      id
      pagesId
      title
      body
      hero {
        url
      }
      createdAt
      publishedAt
      updatedAt
    }
  }
`

export default Biography
