import PropTypes from 'prop-types'
import React from 'react'

const Nav = props => (
  <nav id="nav" style={props.timeout ? { display: 'none' } : {}}>
    <ul>
      <li>
        <button
          onClick={() => {
            props.onOpenArticle('biography')
          }}
        >
          BIOGRAPHY
        </button>
      </li>
      <li>
        <button
          onClick={() => {
            props.onOpenArticle('latestworks')
          }}
        >
          LATEST WORKS
        </button>
      </li>
      <li>
        <button
          onClick={() => {
            props.onOpenArticle('works')
          }}
        >
          WORKS
        </button>
      </li>
      <li>
        <button
          onClick={() => {
            props.onOpenArticle('contact')
          }}
        >
          Contact
        </button>
      </li>
    </ul>
  </nav>
)

Nav.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Nav
