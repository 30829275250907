import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import WorksElement from './WorksElement'

const Works = ({ onOpenModal, onCloseModal }) => {
  const { allVimeo, microcmsSettings } = useStaticQuery(pageQuery)
  const demoreel_ids = microcmsSettings.demoreel.map(({ video_id }) => video_id)
  const [auth, SetAuth] = useState(false)

  let demoreels = []
  let videos = []

  const onSignIn = () => {
    SetAuth(true)
  }

  allVimeo.edges.forEach(element => {
    if (demoreel_ids.includes(element.node.id)) demoreels.push(element.node)
    else videos.push(element.node)
  })

  return (
    <>
      <h2 className="major">WORKS</h2>

      <div className="gallery">
        <h3 className="galleryTitle">DEMO REELS</h3>
        {demoreels && (
          <div className="galleryContainer">
            {demoreels.map((video, i) => {
              return (
                <WorksElement
                  node={video}
                  onOpenModal={onOpenModal}
                  onCloseModal={onCloseModal}
                  onSignIn={onSignIn}
                  isSignedIn={auth}
                  key={video.id}
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="gallery">
        <h3 className="galleryTitle">VIDEO</h3>
        {videos && (
          <div className="galleryContainer">
            {videos.map((video, i) => {
              return (
                <WorksElement
                  node={video}
                  onOpenModal={onOpenModal}
                  onCloseModal={onCloseModal}
                  onSignIn={onSignIn}
                  isSignedIn={auth}
                  key={video.id}
                />
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

Works.propTypes = {
  images: PropTypes.array,
}

export const pageQuery = graphql`
  query {
    allVimeo {
      edges {
        node {
          id
          srcset {
            quality
            type
            width
            height
            link
            created_time
            fps
            size
            md5
            public_name
            size_short
          }
          name
          link
          download {
            quality
            height
            size_short
            link
          }
          pictures {
            uri
            active
            type
            resource_key
            default_picture
            sizes {
              link
            }
          }
        }
      }
    }
    microcmsSettings {
      demoreel {
        video_id
      }
    }
  }
`

export default Works
