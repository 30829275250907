import React from 'react'
import PropTypes from 'prop-types'

import SocialIcons from './Widgets/SocialIcons'

const Footer = props => (
  <footer id="footer" style={props.timeout ? { } : {}}>
    <SocialIcons />
    <p className="copyright">&copy; Hajimex Murakami</p>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
