import React from 'react'

const YouTube = ({ id, videoSrcURL, videoTitle, ...props }) => {
  const wWidth = window.innerWidth
  let vWidth = 640
  if (wWidth < 860) {
    vWidth = 400
  }
  if (wWidth < 500) {
    vWidth = 320
  }

  const vHeight = Math.floor((vWidth / 16) * 9)

  console.log('Video: ', vWidth, vHeight)
  return (
    <div className="YoutubeWrapper" key={id}>
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        width={vWidth}
        height={vHeight}
        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        modestbranding="1"
        allowFullScreen
      />
    </div>
  )
}

export default YouTube
