import React from 'react'
import ReactDom from 'react-dom'
import SignInForm from './SignInForm'

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#ffffff',
  padding: '50px',
  zIndex: 1001,
  maxWidth: '80%',
  minWidth: '40%',
}

const MODAL_STYLES_MOBILE = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#ffffff',
  margin: '0 auto',
  padding: '20px',
  zIndex: 1001,
  maxWidth: '100%',
  minWidth: '80%',
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,.7)',
  zIndex: 1000,
}

const Modal = ({
  open,
  children,
  onCloseModal,
  isPublic = true,
  onSignIn,
  isSignedIn,
}) => {
  if (!open) return null

  const modalStyles =
    typeof window !== `undefined`
      ? window.innerWidth > 500
        ? MODAL_STYLES
        : MODAL_STYLES_MOBILE
      : MODAL_STYLES

  return ReactDom.createPortal(
    <>
      <div
        style={OVERLAY_STYLES}
        onClick={onCloseModal}
        onKeyDown={onCloseModal}
      ></div>
      <div style={modalStyles}>
        {isPublic || isSignedIn ? children : <SignInForm onSignIn={onSignIn} />}
        <button onClick={onCloseModal}>閉じる</button>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default Modal
