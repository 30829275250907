import PropTypes from 'prop-types'
import React from 'react'

class Header extends React.Component {
  render() {
    return (
      <header
        id="header"
        className={`${this.props.articleTimeout ? 'active' : ''} ${
          this.props.articleTimeout ? 'timeout' : ''
        }`}
      >
        <div className="content">
          <div className="inner">
            <div className="heading">
              <h1>HAJIMEX MURAKAMI</h1>
              <h5>Commercial film director based in Tokyo</h5>
            </div>
            <div className="navButton"></div>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
