import React, { useState } from 'react'
import YouTube from '../Widgets/YouTube'
import Modal from '../Widgets/Modal'

const LatestworksElement = ({
  id,
  title,
  video_url,
  thumbnail,
  onOpenModal,
  onCloseModal,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  let handleOpenModal = () => {
    setIsOpen(true)
    onOpenModal()
  }

  let handleCloseModal = () => {
    setIsOpen(false)
    onCloseModal()
  }

  return (
    <div className="galleryItem">
      <img
        src={thumbnail}
        alt={title}
        onClick={handleOpenModal}
        onKeyDown={handleOpenModal}
        className="image fit thumb"
      />
      <h5>{title}</h5>
      <Modal open={isOpen} onCloseModal={handleCloseModal}>
        <h3>{title}</h3>
        <YouTube id={id} videoTitle={title} videoSrcURL={video_url} />
      </Modal>
    </div>
  )
}

export default LatestworksElement
