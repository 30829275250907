import PropTypes from 'prop-types'
import React from 'react'

import Biography from './Pages/Biograpy'
import Contact from './Pages/Contact'
import Latestworks from './Pages/Latestworks'
import Works from './Pages/Works'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        id="main"
        ref={this.props.setWrapperRef}
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="biography"
          className={`${this.props.article === 'biography' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <Biography />
          {close}
        </article>

        <article
          id="latestworks"
          className={`${this.props.article === 'latestworks' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <Latestworks
            onOpenModal={this.props.onOpenModal}
            onCloseModal={this.props.onCloseModal}
          />
          {close}
        </article>

        <article
          id="works"
          className={`${this.props.article === 'works' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <Works
            onOpenModal={this.props.onOpenModal}
            onCloseModal={this.props.onCloseModal}
          />
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <Contact
            onOpenModal={this.props.onOpenModal}
            onCloseModal={this.props.onCloseModal}
          />

          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
