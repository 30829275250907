import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import LatestworksElement from './LatestworksElement'

const Latestworks = ({ onOpenModal, onCloseModal }) => {
  const { allMicrocmsLatestworks } = useStaticQuery(pageQuery)
  const images = allMicrocmsLatestworks.edges

  return (
    <>
      <h2 className="major">LATEST WORKS</h2>
      <p className="small">
        公式サイトで公開中の映像をYOUTUBEリンクにて掲載しています。
      </p>
      <div className="gallery">
        {images && (
          <div className="galleryContainer">
            {images.map((obj, i) => {
              return (
                <LatestworksElement
                  id={obj.node.id}
                  title={obj.node.caption}
                  video_url={obj.node.video_url}
                  thumbnail={obj.node.thumbnail.url}
                  onOpenModal={onOpenModal}
                  onCloseModal={onCloseModal}
                  key={obj.node.id}
                />
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

Latestworks.propTypes = {}

export const pageQuery = graphql`
  query {
    allMicrocmsLatestworks(filter: { show: { eq: true } }) {
      edges {
        node {
          id
          caption
          video_url
          thumbnail {
            url
          }
        }
      }
    }
  }
`

export default Latestworks
