export const isBrowser = () => typeof window !== 'undefined'

export const hasSignedIn = () =>
  Boolean(parseInt(window.localStorage.getItem('hasSignedIn')))

export const handleSignIn = () => {
  window.localStorage.setItem('hasSignedIn', 1)
}

export const handleSignOut = callback => {
  window.localStorage.setItem('hasSignedIn', 0)
  callback()
}
