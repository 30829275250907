import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Modal from '../Widgets/Modal'

const Contact = ({ data, onOpenModal }) => {
  const { microcmsPages, microcmsSettings } = useStaticQuery(pageQuery)
  const { title, body, hero } = microcmsPages
  const { map_pdf } = microcmsSettings
  const [isOpen, setIsOpen] = useState(false)

  let handleOpenModal = () => {
    setIsOpen(true)
    onOpenModal()
  }

  let handleCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <h2 className="major">{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: body }} />
      <img
        src={hero.url}
        className="image fit thumb"
        alt="access map"
        onClick={handleOpenModal}
        onKeyDown={handleOpenModal}
      />
      <a
        href={map_pdf}
        className="button icon fa-download"
        target="_blank"
        rel="noopener"
      >
        MAP DOWNLOAD
      </a>

      <Modal open={isOpen} onCloseModal={handleCloseModal}>
        <img src={hero.url} className="image main fit" alt="access map" />
      </Modal>
    </>
  )
}

export const pageQuery = graphql`
  query {
    microcmsPages(pagesId: { eq: "contact" }) {
      id
      pagesId
      title
      body
      hero {
        url
      }
      createdAt
      publishedAt
      updatedAt
    }
    microcmsSettings {
      map_pdf
    }
  }
`

export default Contact
